.recharts-area-curve {
  filter: drop-shadow(0 0 10px rgba(255, 165, 0, 0.7));
}

.fake-chart {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
}

.fake-chart .bar {
  width: 12%;
  background: linear-gradient(to top, #FFA500, #FFD700);
  border-radius: 5px 5px 0 0;
  transition: height 0.5s ease-in-out;
}

.fake-chart .bar:hover {
  height: 100% !important;
}

.gold-border-glow {
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.5);
}

.enhanced-chart .animate-path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 2s ease-in-out forwards;
}

.enhanced-chart .animate-point {
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.enhanced-chart text {
  font-family: Arial, sans-serif;
}

/* Add any additional styles you want for your component here */
